@import "styles.css";

.App {
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  background-color: #000000;
  box-shadow: 0 0 1px 1px blueviolet;
  min-width: 96vw;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.songList {
  display: flex;
  flex-direction: column;
}

.songCard {
  display: flex;
  justify-content: space-around;
  padding: 5px;
}

.songTitle {
  font-weight: bold;
}

.addCommentButton{
  background-color:#181818;
  color:rgb(255, 255, 255);
  border-width:0px;
  font-size:18px;
  padding-right: .5vw;
}

.addCommentButton:hover {
  background-color:#00d400;
  color:rgb(0, 0, 0);
  border-width:0px;
  font-size:18px;
  padding-right: .5vw;
}


.loadingPage {
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  background-color: #000000;
  min-width: 100vw;
  min-height: 60vh;
  display: flex;
  flex-direction: column;

}


.App-logo {
	height: 100px;
	pointer-events: none;
  }

  .App-link {
	color: #61dafb;
  } 
  
  @media (prefers-reduced-motion: no-preference) {
	.App-logo {
	  animation: App-logo-spin infinite 10s linear;
	}
  }

  
@keyframes App-logo-spin {
	from {
	  transform: rotate(0deg);
	}
	to {
	  transform: rotate(360deg);
	}
  }
  