body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "Space Grotesk", sans-serif;
}
input {
	padding: 10px 20px;
	border: 1px solid #ddd;
	border-radius: 3px;
	margin-bottom: 15px;
}
a {
	text-decoration: none;
}
.register,
.login,
.home,
.replies,
.modal {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 90vh;
	padding: 30px;

}
.registerTitle,
.loginTitle,
.homeTitle,
.repliesTitle {
	margin-bottom: 30px;
}
.registerForm,
.loginForm {
	width: 80%;
	display: flex;
	flex-direction: column;
}
.registerBtn,
.loginBtn,
.homeBtn,
.modalBtn {
	display: block;
	width: 110px;
	padding: 15px;
	cursor: pointer;
	outline: none;
	border: none;
	border-radius: 4px;
	font-size: 16px;
	font-weight: bold;
	color: #f5f5f5;
	background-color: #416288;
	margin-bottom: 15px;
}
.homeForm {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 80%;
	margin-bottom: 30px;
}
.home__container {
	display: flex;
	flex-direction: column;
	width: 75%;
}
.homeBtn {
	margin-bottom: 0px;
	max-width: 110px;
}
.thread__container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

}
.thread__item {

	
	box-shadow: 0 0 1px 1px #c3acd0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;

}
.thread__item2 {

	flex-direction: column;
	box-shadow: 0 0 1px 1px #c3acd0;
	display: flex;
	align-items: center;
	justify-content: space-between;

}
.thread__item3 {

	flex-direction: column;

	display: flex;
	align-items: center;
	justify-content:flex-end;

}
.likes__container {
	display: flex;
	align-items: center;
	
}
.likesBtn {
	height: 30px;
	color: #817c75;
	cursor: pointer;
}
.react__container {
	display: flex;
	align-items: center;
}
.modal {
	background-color: #ddd;
	position: fixed;
}
.modalInput {
	width: 100%;
	padding: 15px;
	margin-bottom: 15px;
	background-color: #000000;
	color: #f5f5f5;
}
.modal__content {
	background-color: #fff;
	width: 70%;
	padding: 30px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.modal__content > label {
	margin-bottom: 15px;
}
.navbar {
	width: 100%;
	padding: 10px 20px;
	background-color: #416288;
	display: flex;
	height: 10vh;
	align-items: center;
	justify-content: space-between;
}
.navbar > h2 {
	color: #fffbf5;
}
.navbarRight {
	display: flex;
	align-items: center;
}
.navbarRight > button {
	padding: 15px;
	width: 150px;
	background-color: #000000;
	color: #fffbf5;
	font-size: 16px;
	border: none;
	outline: none;
	margin-left: 15px;
	cursor: pointer;
	border-radius: 3px;
}
