@import "@aws-amplify/ui-react/styles.css";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

:root {


  --amplify-colors-brand-primary-80: #416288;
 
  --amplify-components-authenticator-max-width: 100vw;
  --amplify-components-authenticator-container-width-max: 400px;

  --amplify-components-authenticator-modal-background-color: #000000;

  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-secondary: #c1c2c4;
  --amplify-components-tabs-item-border-color: #416288;



  

  --amplify-components-authenticator-router-border-color: #416288;



 

  
  
  



}


